import la from '../LazyComponent';

const KYC = la(() => import('@/pages/Report/ReportsKYC'));
const Deposits = la(() => import('@/pages/Report/ReportsDeposits'));
const Withdrawals = la(() => import('@/pages/Report/ReportsWithdrawals'));
const WholesaleAudit = la(() => import('@/pages/Report/ReportsWholesaleAudit'));
const AccountAudit = la(() => import('@/pages/Report/ReportsAccountAudit'));
const CopyTrading = la(() => import('@/pages/Report/ReportsCopyTrading'));
const Performance = la(() => import('@/pages/Report/ReportsPerformance'));
const IbRebate = la(() => import('@/pages/SWSv2Menu/Report/ReportsIbRebate'));
const KYCV2 = la(() => import('@/pages/SWSv2Menu/Report/ReportsKYC'));
const DepositsV2 = la(() => import('@/pages/SWSv2Menu/Report/ReportsDeposits'));
const WithdrawalsV2 = la(() => import('@/pages/SWSv2Menu/Report/ReportsWithdrawals'));
const wholesaleV2 = la(() => import('@/pages/SWSv2Menu/Report/ReportsWholesaleAudit'));
const AccountV2 = la(() => import('@/pages/SWSv2Menu/Report/ReportsAccountAudit'));
const CopyTradingV2 = la(() => import('@/pages/SWSv2Menu/Report/ReportsCopyTrading'));
const PerformanceV2 = la(() => import('@/pages/SWSv2Menu/Report/ReportsPerformance'));
const CPARebate = la(() => import('@/pages/SWSv2Menu/Report/CPARebate'));
const Wallet = la(() => import('@/pages/SWSv2Menu/Report/Wallet'));

const ReportRoutes = [
  {
    path: '/reports',
    element: KYC,
    name: 'menu.Reports',
    meta: {
      key: 'menu.sws.reports',
    },
  },
  {
    path: '/reports/kyc',
    element: KYC,
    name: 'menu.KYC',
    meta: {
      key: 'menu.sws.reports.KYC',
    },
  },
  {
    path: '/reports/deposits',
    element: Deposits,
    name: 'menu.Deposits',
    meta: {
      key: 'menu.sws.reports.Deposits',
    },
  },
  {
    path: '/reports/withdrawals',
    element: Withdrawals,
    name: 'menu.Withdrawals',
    meta: {
      key: 'menu.sws.reports.Withdrawals',
    },
  },
  {
    path: '/reports/wholesaleAudit',
    element: WholesaleAudit,
    name: 'menu.Wholesale',
    meta: {
      key: 'menu.sws.reports.wholesale',
    },
  },
  {
    path: '/reports/account',
    element: AccountAudit,
    name: 'menu.Account',
    meta: {
      key: 'menu.sws.reports.accountAudit',
    },
  },
  {
    path: '/reports/copyTrading',
    element: CopyTrading,
    name: 'menu.Copy Trading',
    meta: {
      key: 'menu.sws.reports.copyTrading',
    },
  },
  {
    path: '/reports/performance',
    element: Performance,
    name: 'menu.Performance',
    meta: {
      key: 'menu.sws.reports.perf',
    },
  },
  {
    path: '/reports/ibRebate',
    element: IbRebate,
    name: 'menu.ibRebate',
    meta: {
      key: 'sws.v2.ib-report',
    },
  },
  {
    path: '/reportsV2',
    element: KYCV2,
    name: 'menu.Reports',
    meta: {
      key: 'sws.v2.reports',
    },
  },
  {
    path: '/reportsV2/kycV2',
    element: KYCV2,
    name: 'menu.KYC',
    meta: {
      key: 'sws.v2.reports.KYC',
    },
  },
  {
    path: '/reportsV2/depositsV2',
    element: DepositsV2,
    name: 'menu.Deposits',
    meta: {
      key: 'sws.v2.reports.Deposits',
    },
  },
  {
    path: '/reportsV2/withdrawalsV2',
    element: WithdrawalsV2,
    name: 'menu.Withdrawals',
    meta: {
      key: 'sws.v2.reports.Withdrawals',
    },
  },
  {
    path: '/reportsV2/wholesaleAuditV2',
    element: wholesaleV2,
    name: 'menu.Wholesale',
    meta: {
      key: 'sws.v2.reports.wholesale',
    },
  },
  {
    path: '/reportsV2/accountV2',
    element: AccountV2,
    name: 'menu.Account',
    meta: {
      key: 'sws.v2.reports.accountAudit',
    },
  },
  {
    path: '/reportsV2/copyTradingV2',
    element: CopyTradingV2,
    name: 'menu.Copy Trading',
    meta: {
      key: 'sws.v2.reports.copyTrading',
    },
  },
  {
    path: '/reportsV2/performanceV2',
    element: PerformanceV2,
    name: 'menu.Performance',
    meta: {
      key: 'sws.v2.reports.perf',
    },
  },
  {
    path: '/reports/cpaRebate',
    element: CPARebate,
    name: 'menu.CPAEarning',
    meta: {
      key: 'sws.v2.reports.cpa-earnings',
    },
  },
  {
    path: '/reports/wallet',
    element: Wallet,
    name: 'menu.wallet',
    meta: {
      key: 'sws.v2.reports.wallet',
    },
  },
];
export default ReportRoutes;
