import { createModel } from '@rematch/core';
import { changeRegulatorData, getAllEnumsData, getSearchEnumsData } from '@/apis/app';
import { uaMobile, xssPreventTransferApi } from '@/utils';
import type { RootModel } from '.';

const defaultCollapsed: boolean = window.innerWidth < 1200;

interface DashboardSwitchItem {
  label: string;
  value: string;
}

interface SalesAttributionWay {
  label: string;
  value: number;
}

export const app = createModel<RootModel>()({
  state: {
    publicLoading: false,
    collapsed: uaMobile() ? true : defaultCollapsed,
    currentRegulator: 'asic',
    lang: sessionStorage.getItem('currentLang') || 'en',
    salesList: [],
    userInfo: {
      username: '',
      userId: '',
      phoneNumber: '',
      clientId: '',
      clientSecret: '',
      defaultRegulator: '',
    },
    countryList: [], // public
    languageList: [], // public
    dataSourceMT: [], // public
    serverEnum: {
      // public
      liveServer: [],
      demoServer: [],
    },
    countryCodes: [], // public
    copyTradingCountryEnum: [], // public
    clientStatusCodes: [], // public
    websiteIdList: [], // public
    depositType: [],
    withdrawType: [],
    isFirstLogin: '', // public
    isPermission: false, // public
    salesStatus: [],
    loginState: localStorage.getItem('sws-loginState') || '', // 登录状态
    // 是否是敏感数据
    isSensitiveAuth: JSON.parse(localStorage.getItem('isSensitiveAuth') || 'false'),
    // 是否展示Trade模块
    isShowTrade: false,
    depositStatusEnum: [], // public
    depositTypeEnum: [], // public
    withdrawTypeEnum: [], // public
    currencyEnum: [], // public
    moduleGuide: {
      search: false,
    }, // 模块引导
    productsOfInterest: [],
    adminWebDashBoardSwitch: [] as DashboardSwitchItem[], // 明确指定类型
    tickStatusEnum: [],
    isFlush: false,
    salesKpiCurrency: [] as SalesAttributionWay[],
    salesAttributionWay: [] as SalesAttributionWay[],
    isBindGoogleFa: false, // 是否绑定谷歌身份验证
    changeBindDevice: false, // 是否修改绑定设备
    headerSelect: [], // 头像下拉选择的key
    isShowSwitchBtn: false, // 是否展示全局切换版本按钮
    phoneService: null,
    regulatorList: [],
    regulatorNoAll: [],
    IBType: [],
    updateTime: '',
    errMessage: '',
  },
  reducers: {
    setPermission: (state, payload: boolean = false) => {
      return {
        ...state,
        isPermission: payload,
      };
    },
    setDepositType: (state, payload) => {
      return {
        ...state,
        depositType: payload,
      };
    },
    setWithdrawType: (state, payload) => {
      return {
        ...state,
        withdrawType: payload,
      };
    },
    setPublicLoading: (state, payload: boolean = false) => {
      return {
        ...state,
        publicLoading: payload,
      };
    },
    setCollapsed: (state, payload: boolean = false) => {
      return {
        ...state,
        collapsed: payload,
      };
    },
    setUserInfo: (state, payload) => {
      sessionStorage.setItem('currentUID', payload.userId);
      return {
        ...state,
        userInfo: payload,
      };
    },
    updateLang: (state, payload: string) => {
      sessionStorage.setItem('currentLang', payload);
      localStorage.setItem('currentLang', payload);
      return {
        ...state,
        lang: payload,
      };
    },
    updateRegulator: (state, payload: string) => {
      sessionStorage.setItem('currentRegulator', payload);
      localStorage.setItem('currentRegulator', payload);
      return {
        ...state,
        currentRegulator: payload,
      };
    },
    setCountryList: (state, payload) => {
      return {
        ...state,
        countryList: payload,
      };
    },
    setCopyTradingCountryEnum: (state, payload) => {
      return {
        ...state,
        copyTradingCountryEnum: payload,
      };
    },
    setLanguageList: (state, payload) => {
      return {
        ...state,
        languageList: payload,
      };
    },
    setDataSourceMT: (state, payload) => {
      return {
        ...state,
        dataSourceMT: payload,
      };
    },
    setServerEnum: (state, payload) => {
      return {
        ...state,
        serverEnum: payload,
      };
    },
    setLoginStatus: (state, payload) => {
      return {
        ...state,
        isFirstLogin: payload,
      };
    },
    setCountryCodes: (state, payload) => {
      return {
        ...state,
        countryCodes: payload,
      };
    },

    setLoginState: (state, payload: string) => {
      localStorage.setItem('sws-loginState', payload);
      return {
        ...state,
        loginState: localStorage.getItem('sws-loginState') || '',
      };
    },
    setClientStatusCodes: (state, payload) => {
      return {
        ...state,
        clientStatusCodes: payload,
      };
    },
    setWebsiteIdList: (state, payload) => {
      return {
        ...state,
        websiteIdList: payload,
      };
    },
    setSalesList: (state, payload) => {
      return {
        ...state,
        salesList: payload,
      };
    },
    setIsShowTrade: (state, payload) => {
      return {
        ...state,
        isShowTrade: payload,
      };
    },
    setIsSensitiveAuth: (state, payload) => {
      return {
        ...state,
        isSensitiveAuth: JSON.parse(localStorage.getItem('isSensitiveAuth') || 'false'),
      };
    },
    setSalesStatus: (state, payload) => {
      return {
        ...state,
        salesStatus: payload,
      };
    },
    setPaymentEnum: (state, payload) => {
      const {
        PaymentDepositStatusEnum,
        PaymentDepositTypeEnum,
        PaymentWithdrawTypeEnum,
        CurrencyEnum,
      } = payload;
      return {
        ...state,
        depositStatusEnum: PaymentDepositStatusEnum,
        depositTypeEnum: PaymentDepositTypeEnum,
        withdrawTypeEnum: PaymentWithdrawTypeEnum,
        currencyEnum: CurrencyEnum,
      };
    },
    setModuleGuide: (state, payload) => {
      return {
        ...state,
        moduleGuide: payload,
      };
    },
    setProductsOfInterest: (state, payload) => {
      return {
        ...state,
        productsOfInterest: payload,
      };
    },
    setAdminWebDashBoardSwitch: (state, payload) => {
      return {
        ...state,
        adminWebDashBoardSwitch: payload,
      };
    },
    setSalesKpiCurrency: (state, payload) => {
      return {
        ...state,
        salesKpiCurrency: payload,
      };
    },
    setSalesAttributionWay: (state, payload) => {
      return {
        ...state,
        salesAttributionWay: payload,
      };
    },
    setTickStatusEnum: (state, payload) => {
      return {
        ...state,
        tickStatusEnum: payload,
      };
    },
    setIsFlush: (state, payload) => {
      return {
        ...state,
        isFlush: payload,
      };
    },
    setIsBindGoogleFa: (state, payload) => {
      return {
        ...state,
        isBindGoogleFa: payload,
      };
    },
    setChangeBindDevice: (state, payload) => {
      return {
        ...state,
        changeBindDevice: payload,
      };
    },
    setHeaderSelect: (state, payload) => {
      return {
        ...state,
        headerSelect: payload,
      };
    },
    setIsShowSwitchBtn: (state, payload) => {
      return {
        ...state,
        isShowSwitchBtn: payload,
      };
    },
    setPhoneService: (state, payload) => {
      return {
        ...state,
        phoneService: payload,
      };
    },
    setRegulatorList: (state, payload) => {
      return {
        ...state,
        regulatorList: payload,
      };
    },
    setRegulatorNoAll: (state, payload) => {
      return {
        ...state,
        regulatorNoAll: payload,
      };
    },
    setIBType: (state, payload) => {
      return {
        ...state,
        IBType: payload,
      };
    },
    setUpdateTime: (state, payload) => {
      return {
        ...state,
        updateTime: payload,
      };
    },
    setErrMessage: (state, payload) => {
      return {
        ...state,
        errMessage: payload,
      };
    },
  },
  effects: (dispatch) => ({
    async changeRegulator(val) {
      return new Promise((resolve, reject) => {
        changeRegulatorData(val)
          .then((res) => {
            resolve(res);
            this.updateRegulator(val);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    async getAllEnums() {
      return new Promise((resolve, reject) => {
        getAllEnumsData()
          .then((res: any) => {
            if (res.success) {
              const { data } = res;

              const {
                PaymentDepositStatusEnum,
                PaymentDepositTypeEnum,
                PaymentWithdrawTypeEnum,
                CurrencyEnum,
              } = data;
              this.setCountryList(data.CountryEnum || []);
              this.setCopyTradingCountryEnum(data.CopyTradingCountryEnum || []);
              this.setLanguageList(data.LocaleEnum || []);
              this.setSalesStatus(data.FollowUpSalesStatusEnum || []);
              this.setServerEnum({
                liveServer: data.LiveServer || [],
                demoServer: data.DemoServer || [],
              });
              this.setDataSourceMT(data.DataSource_MT_Enum || []);
              this.setCountryCodes(data.CountryPhoneCode || []);
              this.setClientStatusCodes(data.TimeLineStepEnum || []);
              this.setWebsiteIdList(data.websiteId || []);
              this.setSalesList(xssPreventTransferApi(data.sales) || []);
              this.setPaymentEnum({
                PaymentDepositStatusEnum,
                PaymentDepositTypeEnum,
                PaymentWithdrawTypeEnum,
                CurrencyEnum,
              });
              this.setProductsOfInterest(data.productsOfInterest || []);
              this.setAdminWebDashBoardSwitch(data.adminWebDashBoardSwitch || []);
              this.setSalesKpiCurrency(data.salesKpiCurrency || []);
              this.setSalesAttributionWay(data.salesAttributionWay || []);
              this.setTickStatusEnum(data.TicketStatusEnum || []);
              this.setIsFlush(false);
              this.setAdminWebDashBoardSwitch(data.adminWebDashBoardSwitch || []);
              this.setRegulatorList(data.regulatorRelation || []);
              this.setRegulatorNoAll(data.regulatorOnlyRelation || []);
              this.setIBType(data.ibType || []);
              resolve(res.data);
            }
          })
          .catch((res) => {
            reject(res);
          });
      });
    },
    async getSearchEnums() {
      return new Promise((resolve, reject) => {
        getSearchEnumsData()
          .then((res: any) => {
            if (res.success) {
              const { data } = res;
              this.setDepositType(data.depositType || []);
              this.setWithdrawType(data.withdrawType || []);
              resolve(res.data);
            }
          })
          .catch((res) => {
            reject(res);
          });
      });
    },
  }),
});
